

<template>
    <vue-timepicker :minute-interval="30" v-model="lazyValue" close-on-complete @change="onChange"></vue-timepicker>
</template>
<script >
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    components: { VueTimepicker },
    props: {
        value: {type:String, default: '',},
    },
    watch :{
        value ( val ) {
            this.lazyValue = val
        }
    }, 
    data() {
        return {
            lazyValue: this.value,
        };
    },
    created() {
        this.lazyValue = this.value
    },
    methods:{
        onChange(e){
            this.$emit('input', e.displayTime)
        }
    }
};
</script>