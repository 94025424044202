<template>
<div class="nuxt__content">
    <!--우리지역 구인구직 상세 -->
    <div class="pd-left-20 pd-right-20">
        <!-- 이미지 리스트 -->
        <div class="image_preview">
            <d-img :src="item.IMG1_PATH" type="S" :defSrc="defSrc" />
            <d-img :src="item.IMG2_PATH" type="S" :defSrc="defSrc" />
            <d-img :src="item.IMG3_PATH" type="S" :defSrc="defSrc" />
        </div>

        <!-- 업소매물 정보 개요 -->
        <div>
            <div class="page_section_title mg-top-24">{{ item.TITLE }}</div>
            <div class="mg-top-20 mg-bottom-24">
                <div class="fs-17 mg-bottom-8">지역 <span class="fs-12 black-ddd mg-right-4 mg-left-4">|</span>
                    <span>{{ item.DO }} {{ item.CITY }} {{ item.DONG }}</span>
                </div>
                <div class="fs-17 mg-bottom-8">근무형태 <span class="fs-12 black-ddd mg-right-4 mg-left-4">|</span>
                    <span>{{ item.GUBUN_NM }}</span></div>
                <div class="fs-17">근무시간 <span class="fs-12 black-ddd mg-right-4 mg-left-4">|</span>
                    <span>{{ item.WORK_ST }} ~ {{ item.WORK_ED }}</span></div>
            </div>
            <button class="blue__btn button_width_100 mg-bottom-24" @click="showCallPop">연락처 보기</button>
        </div>
        <!-- 업소매물 정보 상세 -->
        <div class="fs-17">
            <p class="desc-text">
                {{ item.CONT1 }}
            </p>
        </div>
        <div class="center mg-top-40 mg-bottom-80">
            <button class="white__btn fs-16 mg-right-8" @click="showModPop">수정</button>
            <button class="white__btn fs-16" @click="showDelPop">삭제</button>
        </div>
    </div>
</div>
</template>
<script>
export default {
    layout: "subDefault",
    data() {
        return {
            defSrc: 'img_noimage_70.svg',
            item: {},
        }
    },
    created() {
        this.item.STF_SN = this.$route.query.id
        this.fnSearch()
    },
    mounted() {},
    methods: {
        fnSearch() {
            let param = { SEARCH: { STF_SN: this.item.STF_SN } }
            this.$api.post('/api/restapi/staff/selectMwStaffList', param).then((res) => {
                let list = res.data.LIST
                if (list.length > 0) {
                    this.item = list[0]
                }
            })
        },
        fnCheck(pass, isMod) {
            let param = { SEARCH: { STF_SN: this.item.STF_SN, PASS : pass } }
            this.$api.post('/api/restapi/staff/checkMwStaff', param, { quiet: true }).then((res) => {
                if(res.data.CHECK === 'Y'){

                    if(isMod){  //수정
                        this.$alert.close()
                        this.$router.push({ path: '/staff/StaffNew', query: { id: this.item.STF_SN } })
                    }else{
                        //삭제
                        let delParam = { LIST:[{ STF_SN: this.item.STF_SN, PASS: pass}] }
                        this.$api.post('/api/restapi/staff/deleteMwStaff', delParam).then((res) => {
                            //팝업닫고 목록으로 이동하기
                            this.$alert.close()
                            this.$router.back()
                        })
                    }
                    
                }else{
                    this.$alert.updatepop("비밀번호가 일치하지 않습니다.")
                }
            })
        },        
        showCallPop(){
            if(this.item.TEL){
                this.$alert.callpop(this.item.TEL)
            }else{
                this.$notify("등록된 연락처가 없습니다.")
            }
        },
        showModPop() {
            this.$alert.delpop("", this.modOkCallback)
        },     
        modOkCallback(pass) {
            if (pass) {
                this.fnCheck(pass, true)
            }
        },        
        showDelPop(){
            this.$alert.delpop("", this.delOkCallback )
        },
        delOkCallback(pass){
            if(pass){
                this.fnCheck(pass)
            }
        },
    },
}
</script>