<template>
  <div class="fx-space-between">
    <div v-for="(file, idx) in files" :key="idx" class="file__upload__wrap">
      <label class="file__label" :for="`input-file-${idx}`">
        <img src="@/assets/icon/ico_plus_24dp.svg" alt="">
        <div class="file__upload__wrap_view">
          <div class="file__label__txt">
            이미지 추가
          </div>
          <input :id="`input-file-${idx}`" type="file" accept=".gif, .jpg, .png" @input="onInput($event, idx)">
        </div>
        <div v-if="file.src && file.src !== ''" class="file__preview__box">
          <img class="file__preview__img" :src="file?.src" alt="" @error="replaceNoImg">
          <img class="file__delete__btn" src="@/assets/icon/ico_x_24dp.svg" alt="" @click.prevent="onDelete(idx)">
        </div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
    name: 'DImageUpload',
    props: {
      fileCnt: { type: Number,  default: 1,},
      dtlFiles: { type: Array, default: () => {return []},required: false },
    },
    data () {
        return {
            files:[],
            linkBase:'/',
        }
    },
    watch: {
      dtlFiles() {
        if(this.dtlFiles.length > 0){
          this.files = []
          this.dtlFiles.forEach(file =>{
            if(file.src){
              file.src = (this.linkBase+file.src)
            }
            this.files.push(file)
          })
        }

      },
    },    
    mounted(){
      if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
      }

      for(let i=0;i<this.fileCnt; i++) {
          this.files.push({src: ''})
      }
    },
    methods:{
      onInput(e, idx){
        if(e.target.files && e.target.files[0]) {
          let reader = new FileReader()
          reader.onload = (e) => {
            this.files[idx].src = e.target.result
          }
          reader.readAsDataURL(e.target.files[0])
        } else {
          this.files[idx].src = ''
        }
        this.$emit('inputFile',  idx, e.target.files[0])
      },
      onDelete(idx){
        this.files[idx].src = ''
        this.$emit('deleteFile',  idx)
        //e.preventDefault();
      },
      replaceNoImg(e){
        e.target.src = require(`@/assets/icon/img_noimage_70.svg`)
      },      
    },
}
</script>
<style scoped>

</style>
