<template>
<div class="nuxt__content">
    <!-- 도매장 검색 -->
    <div class="box_border_gray pd-20">
        <div class="page_section_title mg-bottom-12">우리지역 도매장</div>
        <div>
            <div class="input__wrap">
                <input type="text" placeholder="도매장 명을 입력하세요." v-model="search.SCH_TXT">
                <img class="input__x-img" src="@/assets/icon/ico_circle_x.svg" alt="" @click="search.SCH_TXT = ''">
            </div>
            <d-selectbox :items="doList" v-model="search.DO" @change="changeDo" label="도" />
            <div class="fx-justify-content-center mg-top-8">
                <d-selectbox :items="cityList" v-model="search.CITY" @change="changeCity" label="시/군/구" />
                <d-selectbox :items="dongList" v-model="search.DONG" label="동" />
            </div>
            <div>
                <button class="orange__btn" style="width: 100%; margin-top: 8px" @click="fnSearch">검색하기</button>
            </div>
        </div>
    </div>
    <hr class="gray_line"/>
    <!-- 도매장 목록 -->
    <div v-for="(item) in list" :key=item.READE_NO>
        <div class="list_box box_border_gray fx-space-between">
            <div class="list_box__info">
                <div class="fs-18 mg-bottom-4">{{ item.READE_NM }}</div>
                <div class="fs-16 black-555">{{ item.DO }} {{ item.CITY }} {{ item.DONG }}</div>
            </div>
            <div class="list_box__button">
                <div class="list_box__button__gray center">{{ item.ALCOHOL_NO ==='IMP_ALC'? '수입주류' : '종합주류' }}</div>
                <div class="list_box__button__orange center" @click="showCallPop(item.CEO_TEL)">연락처</div>
            </div>
        </div>
        <hr class="gray_line"/>
    </div>
    <div v-if="list.length === 0" class="no-data">자료가 없습니다.</div>
</div>
</template>
<script>
export default {
  layout: "subDefault",
  data () {
        return {
            /* 바인딩 데이터 */
            linkBase:'/',
            /* 조회조건 */
            search : {},
            list:[],
            doList: [],
            cityList:[],
            dongList:[],
            totCityList:[],
            totDongList:[],            
        }
    },
    created(){
        if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
        }
        this.fnSearchRgn()
        this.$nextTick( () => {
            this.fnSearch()
        } )   
    },
    mounted(){
    },
    methods:{
        fnSearch(){
            let param = {SEARCH: this.search}
            this.$api.post('/api/restapi/selectMwTraderList', param).then((res) =>{
                this.list = res.data.LIST
            })
        },
        fnSearchRgn(){
            this.$api.post('/api/restapi/selectMdmRgnDiv').then( res => {
                if(res.data){
                    this.doList = this.$_.cloneDeep(res.data.DO)
                    this.totCityList = this.$_.cloneDeep(res.data.CITY)
                    this.totDongList = this.$_.cloneDeep(res.data.DONG)
                }
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },
        showCallPop(TEL){
            if(TEL){
                this.$alert.callpop2(TEL)
            }else{
                this.$notify("등록된 연락처가 없습니다.")
            }
        },                    
        changeDo (e){
            this.search.CITY = ""
            this.search.DONG = ""
            this.cityList = this.$_.cloneDeep(this.totCityList.filter( item => item.DO === e.CODE))
            this.dongList=[]
        },
        changeCity (e){
            this.search.DONG = ""
            this.dongList = this.$_.cloneDeep(this.totDongList.filter( item => (item.DO === e.DO && item.CITY === e.CODE)))
        },          
    },    
}
</script>

