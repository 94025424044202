<template>
    <div class="nuxt__content">
        <div class="tem-wrap">
            <div class="bold fs-22">
                개인정보처리방침
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    1. 개인정보의 수집 및 이용
                </div>
                <div class="tem-content__txt fs-14">
                    [수집하는 개인정보의 항목] <br/>
                    - 이름, 휴대폰번호, 주소 및 서비스 제공∙이용 과정에서 자동으로 수집되는 정보로서 IP Address, 방문 일시, 서비스 이용 기록, 기기정보
                </div>
                <div class="tem-content__txt fs-14">
                    [개인정보의 수집 및 이용 목적] <br/>
                    - 본인인증, 주류통 서비스의 제공, 고객 문의사항 처리, 고객 요청정보 제공, 서비스 향상을 위한 통계정보의 분석
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    2. 개인정보의 처리위탁
                </div>
                <div class="tem-content__txt fs-14">
                    [수탁자명]<br/> - 주식회사 디지털스케치
                </div>
                <div class="tem-content__txt fs-14">
                    [위탁하는 업무의 내용]<br/> - 서비스 운영 시스템의 개발, 유지보수 및 장애처리
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    3. 개인정보의 처리 및 보유기간
                </div>
                <div class="tem-content__txt fs-14">
                    정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유/이용 기간 내에서 개인정보를 처리 및 보유하며, 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    4. 개인정보 파기절차 및 방법
                </div>
                <div class="tem-content__txt fs-14">
                    개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된
                    개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    5. 정보주체의 권리·의무 및 그 행사방법
                </div>
                <div class="tem-content__txt fs-14">
                    정보주체는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 정보삭제 및 처리정지를 요청할 수도 있습니다. 정보삭제 또는 처리정지를 원하시는 경우 개인정보보호
                    책임자에게 이메일로 연락하시면 지체 없이 조치하겠습니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    6. 개인정보의 안전성 확보조치
                </div>
                <div class="tem-content__txt fs-14">
                    회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 보호조치를 강구하고 있습니다.
                    <br/>
                    ① 관리적 조치내부관계획 수립/시행, 정기적인 취급자 교육 등
                    <br/>
                    ② 기술적 조치개인정보처리시스템 등의 접근권한 관리, 접근통제 시스템 설치, 주요 개인정보의 암호화, 보안프로그램 설치
                    <br/>
                    ③ 물리적 조치전산실, 자료보관실 등의 접근통제
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    7. 개인정보보호 책임자 및 연락
                </div>
                <div class="tem-content__txt fs-14">
                    귀하께서는 홈페이지 등을 이용하시는 과정에서 발생하는 모든 개인정보보호 관련 민원을 개인정보보호 책임자에게 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한
                    답변을 드릴 것입니다.
                    <br/>
                    <br/>
                    개인정보보호 책임자
                    <br/>
                    - 이름 : 김경국
                    <br/>
                    - 소속 : 대외사업팀
                    <br/>
                    - 직위 : 부장
                    <br/>
                    - 메일 : <a href="mailto:kyungkook.kim@drinksinter.com"><span class="blue-highlight">kyungkook.kim@drinksinter.com</span></a>
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    8. 개인정보처리방침의 변경
                </div>
                <div class="tem-content__txt fs-14">
                    현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 ‘공지사항’을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공
                    등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일전에 고지합니다.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  layout: "subDefault",
}
</script>