<template>
    <div class="footer__wrap">
        <img class="cp" @click="$router.go(-1)" src="@/assets/icon/ico_arrow_left_24dp.svg" alt="">
        <nuxt-link to="/"><img class="cp" src="@/assets/icon/ico_home_24dp.svg" alt=""></nuxt-link>
        <img class="cp" @click="goToTop" src="@/assets/icon/ico_arrow_top_24dp.svg" alt="">
    </div>
</template>
<script>
export default {
    name: 'BottomMenu',
    methods:{
        goToTop(){
            // window.scrollTo(0, 0);
            this.$nextTick( () => {
                document.querySelector('.nuxt__content').scrollTo(0, 0)
                window.scrollTo(0, 0)
            } )             

        },
    },
}
</script>