<template>
  <v-text-field
      :rules="ruleList"
      :counter="counter"
      :maxlength="maxLength"
      :placeholder="placeholder"
      :v-model="data"
      @input="updateValue"
  ></v-text-field>
</template>
<script>
export default {
    name: 'DTextField',
    props: {
      data: { type: String,  default: '', required: false},
      type: { type: String,  default: 'text', required: false},
      placeholder: { type: String,  default: '', required: false},
      counter: { type: Boolean,  default: false, required: false},
      required: { type: Boolean,  default: false, required: false},
      maxLength: { type: Number,  default: 50, required: false},
    },
    data(){
      return {
        title: 'Preliminary report',
        email: '',
        ruleList:[], //[rules.required, rules.email]
        rules: {
          required: value => !!value || 'Required.',
          counter: value => {
            if(value){
              value.length <= this.maxLength || ('Max '+ this.maxLength +' characters')
            }
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '잘못된 이메일 형식'
          },
          mobile: value => {
            if(value){
              value.replace(/[^0-9]/g, '')
            }
            const pattern = /^(0[2-8][0-5]?|01[01346-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})$/
            return pattern.test( value) || '잘못된 전화번호 형식'
          },
          number: value => {
            const pattern = /^\d+$/
            return pattern.test(value) || '숫자만 입력해주세요'
          },          
        },
      }
    },
    created(){
      if(this.type === 'email'){
        this.ruleList.push(this.rules.email)
      }else if(this.type === 'mobile'){
        this.ruleList.push(this.rules.mobile)
      }else if(this.type === 'number'){
        this.ruleList.push(this.rules.number)        
      }
      if(this.required){
        this.ruleList.push(this.rules.required)
      }   
    },
    methods:{
      updateValue(e){
        this.$emit('input', e.target.value)
        this.$emit('update:value', e.target.value)
      }      
    },    
}
</script>