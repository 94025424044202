<template>
<input :value="lazyValue" :placeholder="placeholder" :maxlength="maxLength"
 @input="updateValue" 
 oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
</template>
<script>
export default {
    name: 'DNumberField',
    props: {
      lazyValue: { type: String,  default: '', required: false},
      placeholder: { type: String,  default: '', required: false},
      maxLength: { type: String,  default: null, required: false},
    },
    methods:{
      updateValue(e){
        this.$emit('input', e.target.value)
        this.$emit('update:value', e.target.value)
      }
    }
}
</script>