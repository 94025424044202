<!--구인구직 신규 새글-->
<template>
<div class="nuxt__content">
    <div class="offer__write_wrap">
        <div class="offer__write_main">
            <d-image-upload :fileCnt="3" :dtlFiles="dtlFiles" @inputFile="inputFile" @deleteFile="deleteFile"></d-image-upload>
            <div class="offer__info_box">
                <div class="offer__info_title"><d-label essential>제목</d-label></div>
                <div class="input__wrap">
                    <input type="text" placeholder="제목을 입력하세요" v-model="item.TITLE">
                </div>
            </div>
            <div class="offer__info_box">
                <div class="offer__info_title"><d-label essential>지역</d-label></div>
                <v-select :items="doList" v-model="item.DO" @change="changeDo" label="도" item-text="NAME" item-value="CODE" single-line />
                <div class="fx-space-between">
                    <v-select :items="cityList" v-model="item.CITY" @change="changeCity" label="시/군/구" item-text="NAME" item-value="CODE" single-line />
                    <v-select :items="dongList" v-model="item.DONG" label="동" item-text="NAME" item-value="CODE" single-line />
                </div>
            </div>
            <div class="offer__info_box">
                <div class="offer__info_title"><d-label essential>근무형태</d-label></div>
                <v-select :items="gbList" v-model="item.GUBUN" label="근무형태를 선택하세요" item-text="NAME" item-value="CODE" single-line />                
            </div>
            <div class="offer__info_box">
                <div class="offer__info_title"><d-label essential>근무시간</d-label></div>
                <div class="fx-space-between">
                    <d-timepicker v-model="item.WORK_ST" /><span>~</span><d-timepicker v-model="item.WORK_ED" />
                </div>
            </div>
            <div class="offer__info_box">
                <div class="offer__info_title"><d-label essential>연락처</d-label></div>
                <div class="offer__info_tel">
                    <div class="input__wrap">
                        <d-number-field max-length="4" v-model="item.TEL1" placeholder="010" />
                    </div>
                    <div class="input__wrap mg-right-10 mg-left-10">
                        <d-number-field max-length="4" v-model="item.TEL2" />
                    </div>

                    <div class="input__wrap">
                        <d-number-field max-length="4" v-model="item.TEL3" />
                    </div>
                </div>
            </div>
            <div class="offer__info_box" v-if="!$route.query.id">
                <div class="label medium"><d-label essential>비밀번호</d-label></div>
                <div class="input__wrap">
                    <d-number-field placeholder="비밀번호를 설정해주세요 (숫자4자리)" max-length="4" v-model="item.PASS" />
                    <div class="alert__error">{{ errMsg }}</div>
                </div>
            </div>
            <div class="offer__info_box">
                <div class="label medium">상세정보</div>
                <textarea name="" id="" cols="30" rows="10" placeholder="상세정보를 입력해주세요." v-model="item.CONT1" maxlength="1000"></textarea>
            </div>
            <button class="blue__btn mg-top-16 mg-bottom-60" style="width: 100%" @click="fnSave">저장하기</button>
        </div>
    </div>
</div>
</template>
<script>
export default {
    layout: "subDefault",
    data(){
        return{
            linkBase:'/',
            item:{fileIdx:[], udPath:'staff', },
            files:[],
            doList: [],
            cityList:[],
            dongList:[],
            gbList:[],
            totCityList:[],
            totDongList:[],
            workStList:[],
            workEdList:[],
            dtlFiles:[],
            errMsg:'',
        }
    },
    created(){
        if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
        }
        this.fnSearchRgn()
        this.fnCommonCode()
        this.item.STF_SN = this.$route.query.id
    },
    methods:{
        fnCheck() {
            let param = { SEARCH: { STF_SN: this.item.STF_SN, PASS : this.item.PASS } }
            this.$api.post('/api/restapi/staff/checkMwStaff', param, { quiet: true }).then((res) => {
                if(res.data.CHECK === 'Y'){
                    this.saveProcess()
                }else{
                    this.errMsg = "비밀번호가 일치하지 않습니다."
                }
            })
        },
        fnSearch() {
            let param = { SEARCH: { STF_SN: this.item.STF_SN } }
            this.$api.post('/api/restapi/staff/selectMwStaffList', param).then((res) => {
                let list = res.data.LIST
                if (list.length > 0) {
                    this.item = this.$_.cloneDeep(list[0])
                    this.item.fileIdx = []
                    this.item.udPath = 'staff'
                    this.fnSetRgn(list[0])
                    this.fnSetDtlFiles(list[0])
                }
            })
        },        
        inputFile(idx, file){
            this.item.fileIdx.push(idx)
            this.$_.sortBy(this.item.fileIdx)
            this.files[idx] = file
        },
        deleteFile(idx){
            this.$_.remove(this.item.fileIdx, n =>{
                return n === idx
            })
            this.$_.sortBy(this.item.fileIdx)
            this.files[idx] = null

            if(idx === 0){
                this.item.IMG1 = null
            }else if(idx === 1){
                this.item.IMG2 = null
            }else if(idx === 2){
                this.item.IMG3 = null
            }
        },
        fnSave(){
            if( !this.saveValidation() ) return
            
            //수정인 경우 비밀번호 확인
            // if(this.item.STF_SN){
            //     this.fnCheck()
            // }else{
            //     this.saveProcess()
            // }
            this.saveProcess()
        },
        saveProcess(){
            const form = new FormData()
            this.files.forEach(file => {
                form.append('files', file)
            })
            this.item.TEL = this.item.TEL1 + '-' + this.item.TEL2 + '-' + this.item.TEL3
            form.append('param', new Blob([JSON.stringify(this.item)], {type:"application/json"}))
            this.$api.post('/api/restapi/staff/saveMwStaff', form, Object.assign({}, {headers:{'Content-Type': 'multipart/form-data'}})).then((res) =>{
                if(res){
                    this.$router.back()
                }
            }).catch(err =>{ this.$notify.error("에러가 발생하였습니다.")})
        },
        saveValidation(){
            let validChk = true

            if( !this.item.TITLE || String(this.item.TITLE).trim() ===''){
                this.$notify.error("제목을 입력하세요")
                return false
            }

            if( !this.item.DO || String(this.item.DO).trim() ===''){
                this.$notify.error("지역의 도를 선택해주세요.")
                return false
            }
            if( !this.item.CITY || String(this.item.CITY).trim() ===''){
                this.$notify.error("지역의 시를 선택해주세요.")
                return false
            }
            if( !this.item.DONG || String(this.item.DONG).trim() ===''){
                this.$notify.error("지역의 동을 선택해주세요.")
                return false
            }
            if( !this.item.GUBUN || String(this.item.GUBUN).trim() ===''){
                this.$notify.error("근무형태를 선택해주세요.")
                return false
            }
            if( !this.item.WORK_ST || String(this.item.WORK_ST).trim() ===''){
                this.$notify.error("근무시작 시간을 선택해주세요.")
                return false
            }
            if( this.item.WORK_ST.indexOf('HH') !== -1 || this.item.WORK_ST.indexOf('mm') !== -1){
                this.$notify.error("근무시작 시간을 선택해주세요.")
                return false
            }            
            if( !this.item.WORK_ED || String(this.item.WORK_ED).trim() ===''){
                this.$notify.error("근무종료 시간을 선택해주세요.")
                return false
            }
            if( this.item.WORK_ED.indexOf('HH') !== -1 || this.item.WORK_ED.indexOf('mm') !== -1){
                this.$notify.error("근무종료 시간을 선택해주세요.")
                return false
            }            
            if( !this.item.TEL1 || String(this.item.TEL1).trim() ===''){
                this.$notify.error("연락처를 입력하세요.")
                return false
            }
            if( !this.item.TEL2 || String(this.item.TEL2).trim() ===''){
                this.$notify.error("연락처를 입력하세요.")
                return false
            }
            if( !this.item.TEL3 || String(this.item.TEL3).trim() ===''){
                this.$notify.error("연락처를 입력하세요.")
                return false
            }
            if(!this.$route.query.id){
                if( !this.item.PASS || String(this.item.PASS).trim() ===''){
                    this.$notify.error("비밀번호를 입력하세요.")
                    return false
                }
                if( String(this.item.PASS).trim().length !== 4){
                    this.$notify.error("비밀번호 4자리를 입력해주세요")
                    return false
                }  
            }
            return validChk
        },
        fnSearchRgn(){
            this.$api.post('/api/restapi/selectMdmRgnDiv',{},{ quiet: true }).then( res => {
                if(res.data){
                    this.doList = this.$_.cloneDeep(res.data.DO)
                    this.totCityList = this.$_.cloneDeep(res.data.CITY)
                    this.totDongList = this.$_.cloneDeep(res.data.DONG)
                }

                if(this.item.STF_SN){
                    this.fnSearch()
                }   
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },
        fnCommonCode(){
            let cdList = [{MSTR_CD: "STF_GB"}]
            let param = {LIST : cdList}
            this.$api.post('/api/restapi/selectCommonCode', param, { quiet: true }).then( res => {
                if(res.data){
                    this.gbList = res.data.STF_GB
                }
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },        
        changeDo (CODE){
            this.item.CITY = ""
            this.item.DONG = ""
            this.cityList = this.$_.cloneDeep(this.totCityList.filter( it => it.DO === CODE))
        },
        changeCity (CODE){
            this.item.DONG = ""
            this.dongList = this.$_.cloneDeep(this.totDongList.filter( it => (it.DO === this.item.DO && it.CITY === CODE)))
        },
        fnSetRgn(data){
            this.changeDo(data.DO)
            if(data.CITY){
                this.changeCity(data.CITY)
                this.item.CITY = data.CITY
            }
            this.item.DONG = data.DONG
        },
        fnSetDtlFiles(data){
            let files = []
            files.push({idx:1, src:data.IMG1_PATH})
            files.push({idx:2, src:data.IMG2_PATH})
            files.push({idx:3, src:data.IMG3_PATH})
            this.dtlFiles =this.$_.cloneDeep(files)
        }
    },
}
</script>