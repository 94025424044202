<template>
  <button :class="type" :disabled="disabled" :style="{height: height, borderRadius: radius}" @click="$emit('click')">
    <slot></slot>
  </button>
</template>
<script>
export default {
    name: 'DButton',
    props: {
      type: { type: String,  default: 'blue', }, // orange/gray/green/white/blue
      disabled: { type: Boolean,  default: false, },
      height: { type: String,  default: '50px', },
      radius: { type: String,  default: '8px', },
    },
}
</script>

<style scoped>
button {
  padding: 0 20px;
  width: auto;
  cursor: pointer;
  box-sizing: border-box;
}

button > div {
  font-size: 16px;
}

.blue {
  background: transparent linear-gradient(180deg, #0198c6 0%, #0396c3 88%, #018fbb 100%) 0 0 no-repeat padding-box;
  border: 0;
  color: #FFFFFF;
}

.blue:hover {
  background: #0078A6;
}

.green {
  background: transparent linear-gradient(180deg, #009762 0%, #039462 88%, #018e5c 100%) 0 0 no-repeat padding-box;
  border: 0;
  color: #FFFFFF;
}

.green:hover {
  background: #00794e;
}

.gray {
  background: transparent linear-gradient(180deg, #676767 0%, #666666 88%, #616161 100%) 0 0 no-repeat padding-box;
  border: 0;
  color: #FFFFFF;
}

.gray:hover {
  background: #525252;
}

.orange {
  color: #FFFFFF;
  border: 0;
  background: transparent linear-gradient(180deg, #f6a644 0%, #f2a344 88%, #e79b3f 100%) 0 0 no-repeat padding-box;
}

.orange:hover {
  background: #c68536;
}

.white {
  color: #222222;
  border: 1px solid #0000000A;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #00000012 88%, #0000000A 100%) 0% 0% no-repeat padding-box;
}

.white:hover {
  background: #f8f8f8;
}

.orange:disabled, .gray:disabled, .green:disabled, .white:disabled, .blue:disabled {
  color: #AAAAAA;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #00000012 88%, #0000000A 100%) 0 0 no-repeat padding-box;;
}
</style>



