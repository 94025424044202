<script>
export default {
    name:'DLabel',
    functional: true,
    props: {
        essential: { type: Boolean, default: false }
    },

    render ( h, { props, data, children } ) {
        let titleText = ''
        let inner = []
        children.forEach( child => {
            let vNode = child
            let nodeText = vNode.text
            if( vNode.tag === 'br' ) {
                titleText += '\n'
            } else {
                titleText += nodeText
            }
            vNode.text = nodeText
            inner.push( vNode )
        } )

        if ( props.essential ) {
            let marker = h( 'span', {
                staticStyle: {
                    color: 'red'
                }
            }, '*' )
            inner.unshift( marker )
        }
        return h( 'div', {
            staticClass: 'u-label-div',
            attrs: {
                title: titleText
            }
        }, inner )
    }
}
</script>