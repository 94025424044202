<template>
    <div>
        <div class="header__main_wrap">
            <img src="@/assets/icon/header_logo.svg" alt="">
        </div>

        <div class="fs-17">
            <div>
                <img class="main_img" src="@/assets/icon/main_img@2x.png">
            </div>
            <div class="menu_wrap">
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/news/NewsList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_newspaper_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">주류통 뉴스룸</div>
                    </div>
                </div>
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/law/LawList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_feed_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">쓸모 있는 법률&세무</div>
                    </div>
                </div>
                <!--  우리 지역 도매장 -->
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/trader/TraderList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_storefront_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">우리 지역 도매장</div>
                    </div>
                </div>
                <!--  우리 지역 업소매물 -->
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/realty/RealtyList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_place_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">우리 지역 업소매물</div>
                    </div>
                </div>
                <!--  우리 지역 구인구직 -->
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/staff/StaffList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_person_search_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">우리 지역 구인구직</div>
                    </div>
                </div>
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/story/StoryList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_local_bar_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">주류 이야기</div>
                    </div>
                </div>
                <div class="menu_box">
                    <div class="menu_list_container" @click="$router.push(`/notice/NoticeList`)">
                        <div class="menu_icon">
                            <img src="@/assets/icon/ico_local_offer_w_24dp.svg" alt="">
                        </div>
                        <div class="menu_list">주류통 소식지</div>
                    </div>
                </div>
            </div>
            <div class="center">
                <div class="fs-14"><a class="black-888" @click="$router.push(`/etc/Terms`)">이용약관</a> <span class="fs-12 black-ddd mg-right-4 mg-left-4">|</span> <a class="black-888" @click="$router.push(`/etc/Privacy`)">개인정보처리방침</a></div>
            </div>
            <div class="mg-top-16 black-888 fs-13 mg-bottom-30" style="text-align: center; line-height: 22px ">
                <span>DRINKS INTERNATIONAL <br/>
                    서울특별시 서초구 강남대로 279, 3층(서초동, 백향빌딩) <br/>
                    T: 02-566-2600 F: 02-566-2607 <br/>
                    사업자번호 777-86-01234{{$route.query.query}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import commonFn from '@/util/commonFn'

export default {
    layout: "default",
    computed: {
        ...mapGetters( ['offsets', 'pkey'] ),
    }, 
    mounted(){
        this.clearRouteOffset()
        commonFn.chkOnlyRefferer()
    },
    methods:{
        ...mapActions(['clearRouteOffset', 'setActionPKey']),
        validUser(pkey){
            let param = {pkey : pkey}
            this.$api.post('/api/restapi/validMwUser', param).then((res) =>{
                if(res && res.data &&res.data.VALID_CNT>0){
                    this.setActionPKey(this.$router.currentRoute.query.pkey)
                }else{
                    this.setActionPKey('')
                }
            })
        },
        chkPKeyReferrer(){
            let referer = document.referrer
            let qkey = this.$router.currentRoute.query.pkey
            
            if(referer && referer.includes('www.jurutong.com')){
                if(qkey)this.validUser(qkey)
            }            
        },
        // chkOnlyRefferer(){
        //     if (!document.referrer) {
        //         var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;

        //         if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1)
        //         {
        //             window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close";
        //             window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";
        //         }
        //         else if(_ua.toLocaleLowerCase().indexOf("android") > -1)
        //         {
        //             window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";            
        //             self.close();
        //         }
        //         else if(_ua.toLocaleLowerCase().indexOf("iphone") > -1 || _ua.toLocaleLowerCase().indexOf("ipad") > -1 || _ua.toLocaleLowerCase().indexOf("ipod") > -1)
        //         {
        //             window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";            
        //             self.close();
        //         }
        //         else
        //         {
        //             window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";            
        //             self.close();
        //         }
        //     }     
        // }
    }
}
</script>
