<template>
<div class="nuxt__content">
    <div class="pd-20 box_border_gray">
        <div class="fx-space-between mg-bottom-12">
            <div class="page_section_title">우리지역 업소매물</div>
            <button class="white__btn" @click="$router.push(`/realty/RealtyNew`)">글쓰기</button>
        </div>
        <div>
            <div class="input__wrap">
                <!-- <v-text-field class="outlined" v-model="search.SCH_CD" clearable hide-details="auto" /> -->
                <input type="text" placeholder="검색어를 입력하세요." v-model="search.SCH_TXT">
                <img class="input__x-img" src="@/assets/icon/ico_circle_x.svg" alt="" @click="search.SCH_TXT = ''">
            </div>
            <d-selectbox :items="doList" v-model="search.DO" @change="changeDo" label="도" />
            <div class="fx-justify-content-center mg-top-8">
                <d-selectbox :items="cityList" v-model="search.CITY" @change="changeCity" label="시/군/구" />
                <d-selectbox :items="dongList" v-model="search.DONG" label="동" />
            </div>
            <d-selectbox :items="areaList" v-model="search.AREA" label="평수를 선택하세요." />
        </div>
        <div>
            <button class="blue__btn" style="width: 100%; margin-top: 8px" @click="fnSearch">검색하기</button>
        </div>
    </div>
    <hr class="gray_line"/>
    <!-- 업소매물 목록 -->
    <div v-for="(item) in list" :key=item.REAL_SN>
        <div class="list_box box_border_gray fx-space-between">
            <div class="list_box__info fx-justify-content-center">
                <div class="list_box__info__image center">
                    <img v-if="!item.THUMB_PATH" src="@/assets/icon/bar5.jpg" alt=""/>
                    <img v-if="item.THUMB_PATH" :src="linkBase+item.THUMB_PATH" alt=""/>
                </div>
                <div class="center-column" @click="fnDetail(item)">
                    <div class="fs-16 mg-bottom-3">{{ item.TITLE }}</div>
                    <div class="fs-14 black-555 mg-bottom-3 light">{{ item.DO }} {{ item.CITY }} {{ item.DONG }}<span
                            class="fs-12 black-ddd mg-right-4 mg-left-4">|</span>
                        <span class="light" style="color: #F15E21">{{ item.AREA_NM }}</span></div>
                    <div class="fs-14 black-888 light">{{ item.MW_REG_TM }}</div>
                </div>
            </div>
        </div>
        <hr class="gray_line"/>
    </div>
    <div v-if="list.length === 0" class="no-data">자료가 없습니다.</div>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  layout: "subDefault",
  data () {
        return {
            /* 바인딩 데이터 */
            linkBase:'/',
            /* 조회조건 */
            search : {},
            list:[],
            doList: [],
            cityList:[],
            dongList:[],
            areaList:[],
            totCityList:[],
            totDongList:[],            
        }
    },
    computed: {
        ...mapGetters( ['offsets' ] ),
    },    
    created(){
        if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
        }
        this.fnSearchRgn()
        this.fnCommonCode()
        this.$nextTick( () => {
            this.fnSearch()
        } )
    },
    mounted(){    
    },
    methods:{
        ...mapActions(["setRouteOffsetTop"]),         
        fnSearch(){
            let param = {SEARCH: this.search}
            this.$api.post('/api/restapi/realty/selectMwRealtyList', param).then((res) =>{
                this.list = res.data.LIST
                this.fnUpdateScroll()
            })
        },
        fnDetail(item){
            this.fnSetScroll()
            this.$router.push({ path: '/realty/RealtyDtl', query: { id: `${item.REAL_SN}` } })
        },          
        fnSearchRgn(){
            this.$api.post('/api/restapi/selectMdmRgnDiv').then( res => {
                if(res.data){
                    this.doList = this.$_.cloneDeep(res.data.DO)
                    this.totCityList = this.$_.cloneDeep(res.data.CITY)
                    this.totDongList = this.$_.cloneDeep(res.data.DONG)
                }
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },        
        fnCommonCode(){
            let cdList = [{MSTR_CD: "MW_AREA"}]
            let param = {LIST : cdList}
            this.$api.post('/api/restapi/selectCommonCode', param).then( res => {
                if(res.data){
                    this.areaList = res.data.MW_AREA
                }
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },        
        changeDo (e){
            this.search.CITY = ""
            this.search.DONG = ""
            this.cityList = this.$_.cloneDeep(this.totCityList.filter( item => item.DO === e.CODE))
            this.dongList=[]
        },
        changeCity (e){
            this.search.DONG = ""
            this.dongList = this.$_.cloneDeep(this.totDongList.filter( item => (item.DO === e.DO && item.CITY === e.CODE)))
        },
        fnSetScroll(){
            let nuxt_content = document.querySelector('.nuxt__content')
            const offsetTop = nuxt_content.scrollTop
            this.setRouteOffsetTop([this.$route.name, offsetTop])  
        },
        fnUpdateScroll(){
            let currPath = this.$route.name
            let offset = this.offsets[currPath]
            if(offset){
                this.$nextTick( () => {
                    document.querySelector('.nuxt__content').scrollTo(0, offset)
                } )
            }

        },          
    },    
}
</script>