<template>
  <v-select :value="select" :items="lazyItems" :label="label" item-text="NAME" item-value="CODE" @change="onChange"
    return-object
    single-line
  ></v-select>
</template>

<script>
export default {
    name: 'DSelectbox',
    props: {
      select:{ type: Object,  default: () => {} },
      // item: { type: Object,  default: () => {} },
      items: { type: Array,  default: () => [] },
      disabled: { type: Boolean, default: false },
      label: { type: String, default: '선택' },
      hasAll: { type: Boolean, default: true },   
    },
    computed: {
        lazyItems () {
          if(this.hasAll && this.items){
            this.items.unshift( {CODE : '', NAME: this.label}  )
          }
          return this.items
        },
      },    
    methods:{
      onChange(value){
        this.$emit('input', value.CODE);
        this.$emit('change', value)
        this.$emit('update:value', value)
      },
    },
}

</script>

