<template>
<div class="nuxt__content">
    <div class="page_wrap">
        <div class="page_main_img">
            <img src="@/assets/icon/sub01_02@2x.png" alt="">
        </div>
        <div v-for="(item) in list" :key=item.LAW_SN class="page_list_box" @click="fnDetail(item)">
            <div class="page_list">
                <div class="page_list_img"><img :src="linkBase+item.THUMB_PATH" alt=""></div>
                <div class="page_list_info">
                    <div class="page_list_info_span regular">{{ item.TITLE }}</div>
                    <p class="page_list_date">{{ item.MW_REG_TM }}</p>
                </div>
            </div>
        </div>
        <div v-if="list.length === 0" class="no-data">자료가 없습니다.</div> 
    </div>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  layout: "subDefault",
  data () {
        return {
            /* 바인딩 데이터 */
            linkBase:'/',
            source : {},
            /* 조회조건 */
            search : {},
            list:[],
        }
    },
    computed: {
        ...mapGetters( ['offsets' ] ),
    },    
    created(){
        if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
        }
        this.fnSearch()
    },
    mounted(){
    },
    methods:{
        ...mapActions(["setRouteOffsetTop"]),         
        fnSearch(){
            let param = {SEARCH: this.search}
            this.$api.post('/api/restapi/selectMwLawList', param).then((res) =>{
                this.list = res.data.LIST

                this.fnUpdateScroll()
            })
        },
        fnDetail(item){
            this.fnSetScroll()
            this.$router.push({ path: '/law/LawDtl', query: { id: `${item.LAW_SN}` } })
        },          
        fnSetScroll(){
            let nuxt_content = document.querySelector('.nuxt__content')
            const offsetTop = nuxt_content.scrollTop
            this.setRouteOffsetTop([this.$route.name, offsetTop])  
        },
        fnUpdateScroll(){
            let currPath = this.$route.name
            let offset = this.offsets[currPath]
            if(offset){
                this.$nextTick( () => {
                    document.querySelector('.nuxt__content').scrollTo(0, offset)
                } )
            }

        },        
    },  
}
</script>