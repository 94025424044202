<template>
<div class="nuxt__content"> 
    <div class="detail_wrap">
        <div class="detail_main_img">
            <img :src="linkBase+item.TIT_IMG_PATH" alt="">
        </div>
        <div class="detail_info">
            <div class="detail_info_date">{{ item.MW_REG_TM }}</div>
            <div class="detail_info_title">{{ item.TITLE }}</div>
        </div>
        <d-list-item-html v-if="item.CONT1" :dhtml="item.CONT1"/>
        <img v-if="item.IMG1_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG1_PATH" alt="">
        <d-list-item-html v-if="item.CONT2" :dhtml="item.CONT2"/>
        <img v-if="item.IMG2_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG2_PATH" alt="">
        <d-list-item-html v-if="item.CONT3" :dhtml="item.CONT3"/>
        <img v-if="item.IMG3_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG3_PATH" alt="">
        <d-list-item-html v-if="item.CONT4" :dhtml="item.CONT4"/>
        <img v-if="item.IMG4_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG4_PATH" alt="">
        <d-list-item-html v-if="item.CONT5" :dhtml="item.CONT5"/>
        <img v-if="item.IMG5_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG5_PATH" alt="">                                
        <warn-card />
    </div>
</div>
</template>
<script>
export default {
    layout: "subDefault",
    data(){
        return{
            linkBase:'/',
            item:{},
        }
    },
    created(){
        if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
        }

        this.item.NOTI_SN = this.$route.query.id
        this.fnSearch()
    },
    mounted(){
    },
    methods:{
        fnSearch(){
            let param = {SEARCH: { NOTI_SN : this.item.NOTI_SN }}
            this.$api.post('/api/restapi/selectMwNoticeList', param).then((res) =>{
                let list = res.data.LIST
                if(list.length > 0){
                    this.item = list[0]
                }
            })
        }
    },
}
</script>
