<template>
<div class="u-notification" :class="'u-notification-' + params.item.type">
    <div class="d-flex justify-space-between">
        <div class="u-notification-content">
            <span class="u-notification-title">{{ params.item.title }}</span>
            <div class="u-notification-text">{{ params.item.text }}</div>
        </div>
        <!-- <div class="u-notification-close-button">
            <d-button @click="params.close">
            </d-button>
        </div> -->
    </div>
</div>
</template>

<script>
export default {
    name: 'DNotify',
    props: {
        params: { type: Object, required: true }
    },
}
</script>