<template>
<div class="nuxt__content"> 
    <div class="detail_wrap">
        <div class="detail_main_img">
            <img :src="linkBase+item.TIT_IMG_PATH" alt="">
        </div>
        <div class="detail_info">
            <div class="detail_info_date">{{ item.MW_REG_TM }}</div>
            <div class="detail_info_title">{{ item.TITLE }}</div>
        </div>
        <d-list-item-html v-if="item.CONT1" :dhtml="item.CONT1"/>
        <img v-if="item.IMG1_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG1_PATH" alt="">
        <d-list-item-html :style="[item.IMG1_PATH ? '':{'padding-top': 'unset'}]"  v-if="item.CONT2" :dhtml="item.CONT2"/>
        <warn-card />
    </div>
</div>
</template>
<script>
export default {
    layout: "subDefault",
    data(){
    return{
        linkBase:'/',
        item:{},
    }
    },
    created(){
        // if(process.env.NODE_ENV ==='development'){
        //     this.linkBase = process.env.FILE_URL
        // }

        this.item.NEWS_SN = this.$route.query.id
        this.fnSearch()
    },
    mounted(){
    },
    methods:{
        fnSearch(){
            let param = {SEARCH: { NEWS_SN : this.item.NEWS_SN }}
            this.$api.post('/api/restapi/selectMwNewsList', param).then((res) =>{
                let list = res.data.LIST
                if(list.length > 0){
                    this.item = list[0]
                }
            })
        }
    },
}
</script>