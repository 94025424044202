<template>
        <div v-if="visible" class="popup__wrap">
            <div v-if="msgType==='success'" class="popup__box">
                <div class="popup__body medium mg-top-40">
                    {{ message }}
                </div>
                <div class="popup__footer">
                    <!-- 버튼배경색 변경은 버튼 클래스 변경해서 사용하시면 됩니다. -->
                    <button class="gray__btn mg-right-8" style="width: 136px" @click="btnClCallback">취소</button>
                    <button class="blue__btn" style="width: 136px" @click="btnOkCallback">확인</button>
                    <!-- <button class="blue__btn" style="width: 136px" :key="index" v-for="( btn, index ) in btns" :text="btn.label" @click="handleCallback( btn )" />  -->
                </div>
            </div>
            <div v-if="msgType==='callpop'|| msgType ==='callpop2'" class="popup__box">
                <div class="popup__header">
                    <img src="@/assets/icon/ico_x_24dp.svg" alt="" class="cp" @click="close">
                </div>
                <div class="popup__body fx-column">
                    <div class="medium fs-20">
                        {{ title }}
                    </div>
                    <div class="popup__desc mg-top-8 fs-20">
                        {{ message }}
                    </div>
                </div>
                <div class="popup__footer">
                    <a :href="telMsg" ><button class="white__btn mg-right-8" style="width: 136px" @click="btnClCallback">전화걸기</button></a>
                    <a :href="smsMsg"  v-if="msgType==='callpop'"><button class="white__btn" style="width: 136px" @click="btnClCallback">문자보내기</button></a>
                </div>
            </div>
            <div v-if="msgType==='delpop'" class="popup__box">
                <div class="popup__header">
                    <img src="@/assets/icon/ico_x_24dp.svg" alt="" class="cp" @click="close">
                </div>
                <div class="popup__body fx-column">
                    <div class="medium">
                        {{ title }}
                    </div>
                    <div class="popup__desc mg-top-8">
                        <d-number-field placeholder="비밀번호 ( 숫자4자리 ) " max-length="4" v-model="delPass" @input="onInput"/>
                        <div class="alert__error">{{ subMsg }}</div>
                    </div>
                </div>
                <div class="popup__footer mg-top-23">
                    <button class="blue__btn" style="width: 136px" @click="btnOkCallback">확인</button>
                </div>
            </div>                       
        </div>
</template>
<script>

export default {
    name: 'DAlert',
    data () {
        return {
            visible: false,
            title: '',
            message : '',
            msgType: '', // success, callpop, delpop
            telMsg : '#', 
            smsMsg : '#',
            delPass: '',
            subMsg:'',
            okCallback : function(){},
            clCallback : function(){},
        }
    },
    mounted () {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'SHOW-ALERT') {
                this.message = state.message
                this.msgType = state.msgType
                this.okCallback = state.okCallback
                this.clCallback = state.clCallback
                this.setAlertDetail(state.message)
                this.show()
            }else if(mutation.type === 'UPDATE-ALERT'){
                if(this.visible){
                    if(this.msgType === state.msgType){
                        this.subMsg = state.subMsg
                    }
                }
            }else if(mutation.type === 'CLOSE-ALERT'){
                this.close()
            }
        })

    },
    methods : {
        onInput(){
            if(this.msgType === 'delpop'){
                this.subMsg = ''
            }
        },
        btnOkCallback(){
            switch(this.msgType){
                case 'success':
                    this.okCallback()
                    this.close()
                    break;
                case 'callpop':
                case 'callpop2':
                    this.okCallback()
                    break;
                case 'delpop':
                    this.okCallback(this.delPass)
                    break;
            }
        },
        btnClCallback(){
            this.clCallback()
            if(this.msgType === 'success'){
                this.close()
            }
        },
        show(){
            this.visible = true
            self.addEventListener( 'keyup', this.onKeyEvent)
        },
        close () {
            this.visible = false
            this.fnReset()
            self.removeEventListener( 'keyup', this.onKeyEvent)
        },
        fnReset(){
            this.title = ''
            this.message = ''
            this.msgType = ''
            this.telMsg = '#' 
            this.smsMsg = '#'
            this.delPass = ''
            this.subMsg =''
            this.okCallback = function(){}
            this.clCallback = function(){}
        },
        setAlertDetail(message){
            switch(this.msgType){
                case 'callpop':
                case 'callpop2':
                    this.title = "TEL"
                    this.telMsg = "tel:" + message
                    this.setSmsMsg(message)
                    break;
                case 'delpop':
                    this.title = "작성시 입력한 비밀번호를 확인해주세요."
                break;                    
            }

        },
        setSmsMsg(TEL){
            //let body = "주류통보고 연락드립니다."
            //let os = this.$mobileDetect.os()
            //let tstOs = this.$device.isIos
            // let os = 'AndroidOS'
            // console.log("os : " + os )
            // if(os === 'AndroidOS'){
            //     this.smsMsg =  "sms:" + TEL +"?body=" + body
            // }else if(os === 'iOS'){
            //     this.smsMsg =  "sms:" + TEL +"&body=" + body
            // }
            this.smsMsg =  "sms:" + TEL
        },
        onKeyEvent(e) {
            if(e.key === 'ESC'){
                this.visible = false
            }
                
            //if(e.key === 'Enter' || e.key === ' ') this.handleCallback(this.btns[0])
        }

    }
}
</script>