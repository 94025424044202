<template>
<v-app>
  <div class="nuxt__wrap">
    <client-only>
      <notifications group="global" position="bottom right" width="300px" :speed=200 >
      <template #body="props">
              <d-notify :params="props"/>
          </template>
      </notifications>
      <v-overlay :value="isHttpTransaction" z-index="1000">
            <v-progress-circular indeterminate color="primary" :width="3" :size="150">
                <!-- <v-subheader class="display-1">Loading</v-subheader> -->
            </v-progress-circular>
      </v-overlay>
    </client-only>
    <d-alert />
    <header-sub></header-sub>
    <nuxt></nuxt>
    <bottom-menu></bottom-menu>
  </div>
</v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import commonFn from '@/util/commonFn'

export default {
  computed: {
        ...mapGetters( [ 'isHttpTransaction', 'pkey' ] ),
  },
  mounted(){
    commonFn.chkOnlyRefferer()
  }, 
  methods:{
    ...mapActions(['setActionPKey']),
    validUser(pkey){
      let param = {pkey : pkey}
      this.$api.post('/api/restapi/validMwUser', param).then((res) =>{
          if(res && res.data &&res.data.VALID_CNT>0){
              this.setActionPKey(this.$router.currentRoute.query.pkey)
          }else{
              alert('채널인증을 확인해주세요')
              this.setActionPKey('')
              this.$router.push('/')
          }
      })
    },
    chkPKeyReferrer(){
      let referer = document.referrer
      let qkey = this.$router.currentRoute.query.pkey
      if(referer && referer.includes('www.jurutong.com')){
        if(qkey){
          this.validUser(qkey)
        }else{
          if(!this.pkey){     
            alert("인증여부를 확인해주세요")
            this.$router.push('/')
            return
          }
        }
      }else{
          if(!this.pkey){
            alert("카카오톡 \‘주류통\’ 채널을 통해 접속해 주세요")
            this.$router.push('/')
            return
          }
      }
    },
  },
}
</script>
