<template>
    <div class="pd-20">
        <div class="detail_info_warning_box fs-12 medium center black-555">
            * 지나친 음주는 뇌졸증, 기억력 손상이나 치매를 유발합니다.<br/>
            임신중 음주는 기형아 출생 위험을 높입니다.
        </div>
    </div>
</template>

<script>
export default {
    name: 'WarnCard',
};
</script>
