<template>
<div class="nuxt__wrap">
  <header></header>
  <nuxt class="nuxt__content"></nuxt>
  <!-- <Footer></Footer> -->
</div>
</template>
<script>
import Header from '../components/core/Header.vue'
export default {
  components: { Header },
}
</script>