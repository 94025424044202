<template>
<div class="nuxt__content"> 
    <div class="detail_wrap">
        <div class="detail_main_img">
            <img :src="linkBase+item.TIT_IMG_PATH" alt="">
        </div>
        <div class="detail_info">
            <div class="detail_info_date">{{ item.MW_REG_TM }}</div>
            <div class="detail_info_title">{{ item.TITLE }}</div>
        </div>
        <d-list-item-html v-if="item.CONT1" textColor="#f15e21" :dhtml="item.QUESTION"/>
        <d-list-item-html v-if="item.CONT2" :dhtml="item.ANSWER"/>
        <img v-if="item.IMG1_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG1_PATH" alt="">
        <d-list-item-html v-if="item.CONT3" :dhtml="item.CONT3"/>
        <img v-if="item.IMG2_PATH" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="linkBase+item.IMG2_PATH" alt="">        
        <warn-card />
    </div>
</div>
</template>
<script>
export default {
    layout: "subDefault",
    data(){
    return{
        linkBase:'/',
        item:{},
        qtag:'Q. ',        
        atag:'<span style="color: #009762;">A.</span> ',
    }
    },
    created(){
        // if(process.env.NODE_ENV ==='development'){
        //     this.linkBase = process.env.FILE_URL
        // }

        this.item.LAW_SN = this.$route.query.id
        this.fnSearch()
    },
    mounted(){
    },
    methods:{
        fnSearch(){
            let param = {SEARCH: { LAW_SN : this.item.LAW_SN }}
            this.$api.post('/api/restapi/selectMwLawList', param).then((res) =>{
                let list = res.data.LIST
                if(list.length > 0){
                    this.item = list[0]
                    if(this.item.CONT1){
                        this.item.QUESTION = this.qtag + this.item.CONT1
                    }                    
                    if(this.item.CONT2){
                        this.item.ANSWER = this.atag + this.item.CONT2
                    }
                }
            })
        }
    },
}
</script>
