<template>
    <div class="nuxt__content">
        <div class="tem-wrap">
            <div class="bold fs-22">
                주류통 서비스 이용약관
            </div>
            <div class="tem-content">
                <div class="tem-content__title bold fs-16">제 1장 총칙</div>
                <div class="tem-content__sub-title bold fs-14">
                    제 1 조 (목적)
                </div>
                <div class="tem-content__txt fs-14">
                    본 약관은 “드링크인터내셔널(이하 “회사”라 한다)”가 운영하는 “주류통”(이하 “서비스”)의 이용과 관련하여 “회사”와 “이용자” 사이의 권리, 의무 및 책임사항, 기타 필요한
                    사항에 대하여 규정함을 목적으로 합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제 2 조 (용어의 정의)
                </div>
                <div class="tem-content__txt fs-14">
                    ①이 약관에서 사용되는 용어는 다음과 같은 의미를 가집니다.
                    <br>
                    <br>
                    1. “주류통”(이하 “서비스”)라 함은, “모바일 대화 플랫폼”(‘카카오톡’ 등)을 통해 “주류통” 모바일
                    웹페이지(공식소통채널 landing page 도메인 주소)에 접근할 수 있는 서비스로서, “이용자”들이 공통으로 관심 갖는 업계소식, 법률/세무정보, 주류상식 및 회사의 상품 및
                    서비스에 관한 정보제공 및 정보조회 등을 제공하고, “이용자”들 사이의 정보 공유 및 커뮤니티 환경을 제공하는 서비스를 말합니다.
                    <br>
                    <br>
                    2. “이용자 모바일기기”라 함은, 스마트폰,
                    컴퓨터, 노트북, 태블릿PC 등 모바일 대화 플랫폼 연결이 가능하여 인터넷 상의 컨텐츠를 검색, 설치, 이용 또는 보관할 수 있는 일체의 제품을 말합니다.
                    <br>
                    <br>
                    3. “이용자”라 함은,
                    “모바일 대화 플랫폼”을 통해 “주류통” 서비스에 접속하여 회사로부터 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속하여 이용하는 자를 말합니다. 이용자는 다음 각
                    목에 따라 구분됩니다.
                    <br>
                    가. “예비회원”이란, “모바일 대화 플랫폼”에서 “주류통” 서비스 채널을 추가하고, 임시인증번호를 발급받아 본 약관에 동의한 자를 말합니다.
                    <br>
                    나.
                    “준회원”이란, “예비회원” 중 이름, 휴대폰번호, 인증번호, 상호명, 지역, 거래선 정보를 “회사”에 제공하고, 개인정보제공 동의서에 동의한 자를 말합니다.
                    <br>
                    다. “정회원”이란,
                    “준회원” 중 “회사”가 지정한 자(“키맨”)로부터 인증 절차를 마친 자를 말합니다.
                    <br>
                    <br>
                    4. “컨텐츠”라 함은, 본 서비스를 이용하는 과정에서 유통되는 텍스트, 파일, 사진, 동영상
                    기타 일체의 정보를 말합니다.
                    <br>
                    <br>
                    ② 전항에 규정되지 않은 용어의 정의는 관련 법령 및 일반 상관례에 따릅니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제3조 (적용범위)
                </div>
                <div class="tem-content__txt fs-14">
                    본 약관은 회사가 이용자에게 제공하는 서비스에 적용합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제4조 (약관의 효력과 변경)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 “주류통” 모바일 웹페이지(https://kakaochannel.jurutong.com/) 및 회사
                    홈페이지(http://drinksinter.com/ )에 게시합니다. <br/><br/>②회사는 약관의 규제에 관한 법률, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률,
                    전자상거래 등에서의
                    소비자보호에 관한 법률 등 관련 법령을 위반하지 않는 범위에서 이 약관을 개정할 수 있으며, 이 경우에는 적용일자 및 개정사유, 변경되는 내용을 명시하여 현행 약관과 함께 제1항의
                    방식에 따라 그 적용일자 10일 전(이용자에게 불리하거나 중대한 사항의 변경의 경우 30일 전)부터 적용일자 전일까지 회사 홈페이지에 게시하거나, 서비스를 통해 이용자에게
                    공지합니다.<br/><br/> ③제2항에 따라 개정된 약관은 이용자가 명시적인 반대의사를 표시하지 않는 한 제2항에서 정한 공지기간이 종료됨과 동시에 효력이
                    발생합니다.<br/><br/> ④이용자는 변경된 약관에
                    동의하지 않을 경우 서비스 이용을 중단하고 서비스 탈퇴를 요청할 수 있습니다. 변경된 약관의 공지기간 종료 이후 이용자의 계속적인 서비스 이용은 이 약관의 변경사항에 동의한 것으로
                    간주합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제5조 (준용규정)
                </div>
                <div class="tem-content__txt fs-14">
                    본 약관에 명시되지 않은 사항에 대해서는 전자상거래법 및 관련 법령, 공정거래위원회 전자상거래(인터넷사이버몰) 표준약관에 따릅니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__title bold fs-16">제 2장 서비스의 가입 및 이용</div>
                <div class="tem-content__sub-title bold fs-14">
                    제6조 (서비스 이용 계약의 체결)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 서비스 이용계약은 서비스를 이용하려는 자가 이 약관을 동의한다는 의사표시와 함께 서비스 이용을 승낙함으로써 체결됩니다.
                    <br>
                    <br>
                    ② 회사는 이용자의 개인정보를 수집·이용함에 있어
                    개인정보보호법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 등 관련 법령 및 회사의 개인정보처리방침을 준수합니다.
                    <br>
                    <br>
                    ③ 서비스는 만 19세 미만의 개인에게는 이용이 허용되지
                    않습니다.
                    <br>
                    <br>
                    ④ 회사는 다음 각 호에 해당하는 서비스 이용에 대해서는 승낙하지 아니합니다.
                    <br><br>1. 타인의 정보를 이용한 서비스 접속
                    <br>2. 관련 법령 위반 또는 범죄 등 악의적 목적으로
                    사용이 확인될 시
                    <br>3. 부정한 용도로 서비스 이용이 확인될 시
                    <br>4. 규정된 제반사항을 위반하거나 기타 이용자의 귀책사유로 이용이 곤란한 경우로 판단될 시
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제7조 (서비스 이용 및 제한)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 이용자는 본 서비스 이용약관에 동의함으로써 서비스 이용이 가능합니다. 단, 예비회원, 준회원, 정회원 구분에 따른 서비스 이용 범위는 달라질 수 있습니다.
                    <br>
                    <br>
                    ② 회사는
                    정보처리시스템 등의 정기 점검, 보수, 교체, 장애 등의 특별한 사유가 발생한 경우에는 서비스의 제공을 일시 중단할 수 있습니다.
                    <br>
                    <br>
                    ③ 제2항에 의한 서비스 중단의 경우에는 회사는
                    이용자에게 회사 홈페이지에 게시하거나, 서비스를 통해 이용자에게 사전 통지합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스 중단으로 사전 통지가 불가능한 경우에는 그러하지
                    않습니다.
                    <br>
                    <br>
                    ④ 본 서비스는 기본적으로 무료로 제공됩니다. 단, 모바일 기기 사용환경에 따라 서비스 이용에 따른 데이터 사용료는 부과될 수 있으며, 이 경우, 이용자가 해당 요금을
                    부담하게 됩니다.
                    <br>
                    <br>
                    ⑤ 회사는 서비스를 통하여 이용자의 개인정보를 포함한 정보를 서비스를 통하여 제공할 수 있으며, 정보가 이용자에게 도달한 이후의 정보에 대한 관리책임은 이용자에게
                    있습니다.
                    <br>
                    <br>
                    ⑥ 회사는 본 서비스의 이용자의 관리부주의로 인해 발생한 손해에 대해서는 책임지지 않습니다.
                    <br>
                    <br>
                    ⑦ 회사는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다.
                    다만, 이러한 경우에는 그 내용을 이용자에게 사전에 공지합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__title bold fs-16">제 3장 회사 및 이용자의 의무</div>
                <div class="tem-content__sub-title bold fs-14">
                    제8조 (회사의 의무)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 회사는 특별한 사정이 없는 한 이용자가 신청한 서비스에 대한 이용계약이 성립된 날에 서비스 제공을 개시합니다. <br><br>
                    ② 회사는 이 약관에서 정한 바에 따라 안정적인 서비스 제공이 가능하도록 최선의 노력을 다합니다. <br><br>
                    ③ 회사는 이용자로부터 절차에 따라 제기되는 서비스에 대한 요구사항에 대하여는 내부 절차에 따라 적용합니다. <br><br>
                    ④ 회사는 이용자의 정보를 철저히 보안 유지하며, 이용자의 동의가 없는 한 제3자에게 제공하지 않습니다. <br><br>
                    ⑤ 개인정보의 보호 및 이용에 대해서는 개인정보보호법령 및 회사의 개인정보처리방침이 적용됩니다. <br>
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제9조 (이용자의 의무)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 이용자는 이용자 정보관리의 책임을 부담하며, 이용자의 귀책사유로 인한 정보유출로 인한 손해에 대해서는 회사가 책임을 지지 않습니다.<br><br>
                    ② 이용자는 이 약관 및 관계 법령에서 규정한 사항을 준수합니다.<br><br>
                    ③ 이용자는 다음 각 호의 행위를 하여서는 안됩니다.<br><br>
                    1. 타인을 사칭한 신청 또는 허위내용의 등록<br>
                    2. 주류통 서비스 이용 목적과 무관한 정보의 송신 또는 게시<br>
                    3. 저작권 등 지적재산권에 대한 침해<br>
                    4. 서비스를 통하여 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
                    5. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__title bold fs-16">제 4장 서비스 이용중지</div>
                <div class="tem-content__sub-title bold fs-14">
                    제10조 (서비스 이용중지)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 이용자가 서비스 화면에서 나가기 및 종료 버튼을 누르면, 서비스 이용이 종료됩니다.<br><br>
                    ② 회사는 이용자가 다음 각 호에 해당하는 행위를 하였을 경우, 상당한 기간을 정하여 서비스 이용을 중지할 수 있습니다.<br><br>
                    1. 타인의 정보를 임의로 이용한 서비스 이용<br>
                    2. 법령의 위반 및 범죄적 행위에 관련되는 경우<br>
                    3. 타인의 명예를 손상시키거나 불이익을 주는 경우<br>
                    4. 서비스의 건전한 이용을 저해하는 경우<br>
                    5. 회사 및 서비스 이용자의 이용조건에 위배되는 경우<br><br>
                    ③ 회사는 이용자가 서비스 운영을 고의로 방해하여 회사에 손해를 입한 경우, 사전통지 없이 서비스를 종료할 수 있으며, 관련 법령에 따라 책임을 물을 수 있습니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제11조 (서비스 이용중지 시 구제 절차)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 제10조의 규정에 의하여 서비스 이용 중지를 통지받은 자는 서비스 이용중지에 대하여 이메일(jurutong.servicedesk@drinksinter.com)을 통해 이의신청을
                    할 수 있습니다.<br><br>
                    ② 회사는 서비스 이용중지 기간 중에 그 이용중지 사유가 해소된 것이 확인된 경우나, 이용자의 이의신청에 이유가 있는 경우 등에 한하여 이용중지 조치를
                    해제합니다.

                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__title bold fs-16">제 5장 일반사항</div>
                <div class="tem-content__sub-title bold fs-14">
                    제12조 (손해배상)
                </div>
                <div class="tem-content__txt fs-14">
                    회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 관련 법에서 정하는 바에 따라 이를 배상하여야 합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제13조 (면책조항)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 회사는 천재지면 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br><br>
                    ② 회사는 이용자의 귀책사유로 인한 서비스 이용장애에 대하여 책임을 지지 않습니다.<br><br>
                    ③ 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실하는 것이나, 서비스를 통하여 얻은 정보로 인한 손해에 관하여 책임을 지지 않습니다.<br><br>
                    ④ 회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.<br><br>
                    ⑤ 회사는 서비스 이용과 관련하여 이용자에게 발생한 손해 가운데 이용자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.<br><br>
                    ⑥ 회사는 이용자의 모바일 기기 오류에 의한 서비스 제공 불가에 대하여 책임을 지지 않습니다.<br><br>
                    ⑦ 이용자가 본 서비스를 이용함에 있어 행한 불법행위나 이 약관의 위반행위로 인하여 회사가 당해 이용자 이외의 제3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는
                    경우, 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상하여야
                    합니다.<br><br>
                    ⑧ 서비스와 관련하여 이용자와 제3자간에 분쟁이 발생한 경우, 회사는 그 분쟁에 개입하지 않으며, 분쟁의 결과에 대하여 책임이 없습니다. 다만, 이와 관련하여 회사가 제3자에게
                    손해를 배상하거나 기타 비용을 지출한 경우 회사는 이용자에게 구상권을 행사할 수 있습니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제14조 (이용자에 대한 통지 및 정보제공)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 이용자에 대하여 통지하는 경우에는 회사 홈페이지 및 서비스 공지 화면을 통해 이용자에게 안내됩니다. <br><br>
                    ② 회사는 이용자에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서는 서비스 공지 화면을 통해 이용자에게 제공할 수 있습니다. <br><br>
                    ③ 회사는 서비스 개선 및 서비스 소개 등의 목적으로 이용자의 동의 하에 관련 법령에 따라 추가적인 개인정보를 수집할 수 있습니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제15조 (일부 무효)
                </div>
                <div class="tem-content__txt fs-14">
                    관련 법령 등에 의하여 본 약관의 일부 규정이 무효가 될 경우, 나머지 규정만으로 그 목적을 달성하기 불가능하거나 이용자에게 부당하게 불리하지 아니한 이상, 본 약관은 그 효력이
                    인정됩니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    제16조 (재판관할 및 준거법)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 본 약관에 명시되지 않은 사항은 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래법, 개인정보보호법 등 대한민국의 관계 법령과 상관습에 따릅니다.
                    <br>
                    <br>
                    ② 회사가 기타 서비스를 이용하려는 경우 당해 서비스와 관련하여서는 회사가 별도로 정한 약관 및 정책을 따릅니다.<br><br>
                    ③ 서비스 이용과 관련하여 회사와 이용자 간에 발생하는 분쟁과 관련한 모든 분쟁은 서울중앙지방법원을 합의관할로 합니다.
                </div>
            </div>
            <div class="tem-content">
                <div class="tem-content__sub-title bold fs-14">
                    부칙(2023. 3. 1.)
                </div>
                <div class="tem-content__txt fs-14">
                    ① 본 약관은 2023년 3월 1일 부터 시행됩니다.<br><br>
                    ② 주류통 서비스와 관련하여 회사와 이용자 간에 개별 약정이 존재하는 경우, 개별 약정은 본 약관 시행일부터 본 약관으로 대체됩니다.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        layout: "subDefault",
    }
</script>