<template>
<div class="nuxt__content">
    <div class="pd-20 box_border_gray">
        <div class="fx-space-between mg-bottom-12">
            <div class="page_section_title">우리지역 구인구직</div>
            <button class="white__btn" @click="$router.push(`/staff/StaffNew`)">글쓰기</button>
        </div>
        <div class="input__wrap">
            <input type="text" placeholder="검색어를 입력하세요." v-model="search.SCH_TXT">
            <img class="input__x-img" src="@/assets/icon/ico_circle_x.svg" alt="" @click="search.SCH_TXT = ''">
        </div>
        <d-selectbox :items="doList" v-model="search.DO" @change="changeDo" label="도" />
        <div class="fx-justify-content-center mg-top-8">
            <d-selectbox :items="cityList" v-model="search.CITY" @change="changeCity" label="시/군/구" />
            <d-selectbox :items="dongList" v-model="search.DONG" label="동" />
        </div>
        <div>
            <button class="blue__btn" style="width: 100%; margin-top: 8px" @click="fnSearch">검색하기</button>
        </div>
    </div>
    <hr class="gray_line"/>
    <!-- 구인구직 목록 -->
    <div v-for="(item) in list" :key=item.REAL_SN>
        <div class="list_box_job box_border_gray" @click="fnDetail(item)">
            <div class="pd-20">
                <div class="fs-16">{{ item.TITLE }}</div>
                <div class="fx-align-items-center mg-top-6">
                    <div class="list_box__gray_box center mg-right-8">{{ item.GUBUN_NM }}</div>
                    <div class="list_box__gray_box center">{{ item.WORK_ST }} ~ {{ item.WORK_ED }}</div>
                </div>
                <div class="mg-top-10 fs-14 light black-555">{{ item.DO}} {{ item.CITY }} {{ item.DONG }}
                    <span class="fs-12 black-ddd mg-right-4 mg-left-4">|</span>
                    <span class="fs-14 black-888 light">등록일 {{ item.MW_REG_TM }}</span></div>
            </div>
        </div>
        <hr class="gray_line"/>
    </div>
    <div v-if="list.length === 0" class="no-data">자료가 없습니다.</div>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  layout: "subDefault",
  data () {
        return {
            /* 바인딩 데이터 */
            linkBase:'/',
            /* 조회조건 */
            search : {},
            list:[],
            doList: [],
            cityList:[],
            dongList:[],
            areaList:[],
            totCityList:[],
            totDongList:[],            
        }
    },
    computed: {
        ...mapGetters( ['offsets' ] ),
    },    
    created(){
        if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
        }
        this.fnSearchRgn()
        this.fnCommonCode()
        this.$nextTick( () => {
            this.fnSearch()
        } )         
    },
    mounted(){    
    },
    methods:{
        ...mapActions(["setRouteOffsetTop"]),         
        fnSearch(){
            let param = {SEARCH: this.search}
            this.$api.post('/api/restapi/staff/selectMwStaffList', param).then((res) =>{
                this.list = res.data.LIST

                this.fnUpdateScroll()
            })
        },
        fnDetail(item){
            this.fnSetScroll()
            this.$router.push({ path: '/staff/StaffDtl', query: { id: `${item.STF_SN}` } })
        },          
        fnSearchRgn(){
            this.$api.post('/api/restapi/selectMdmRgnDiv').then( res => {
                if(res.data){
                    this.doList = this.$_.cloneDeep(res.data.DO)
                    this.totCityList = this.$_.cloneDeep(res.data.CITY)
                    this.totDongList = this.$_.cloneDeep(res.data.DONG)
                }
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },        
        fnCommonCode(){
            let cdList = [{MSTR_CD: "STF_GB"}]
            let param = {LIST : cdList}
            this.$api.post('/api/restapi/selectCommonCode', param).then( res => {
                if(res.data){
                    this.areaList = res.data.MW_AREA
                }
            } ).catch( rej => {
                this.$notify.error( rej.message )
            } )
        },        
        changeDo (e){
            this.search.CITY = ""
            this.search.DONG = ""
            this.cityList = this.$_.cloneDeep(this.totCityList.filter( item => item.DO === e.CODE))
            this.dongList=[]
        },
        changeCity (e){
            this.search.DONG = ""
            this.dongList = this.$_.cloneDeep(this.totDongList.filter( item => (item.DO === e.DO && item.CITY === e.CODE)))
        },
        fnSetScroll(){
            let nuxt_content = document.querySelector('.nuxt__content')
            const offsetTop = nuxt_content.scrollTop
            this.setRouteOffsetTop([this.$route.name, offsetTop])  
        },
        fnUpdateScroll(){
            let currPath = this.$route.name
            let offset = this.offsets[currPath]
            if(offset){
                this.$nextTick( () => {
                    document.querySelector('.nuxt__content').scrollTo(0, offset)
                } )
            }

        },            
    },    
}
</script>