<template>
<img v-if="imgPath" class="detail_info_textImg mg-top-4 mg-bottom-4" :src="lazyPath" alt="" @error="replaceNoImg">
</template>
<script>
export default {
    name: 'DImg',
    props: {
      show: { type: Boolean,  default: false, required: false},
      src: { type: String,  default: '', required: false},
      type: { type: String,  default: 'B', required: false},
      defSrc : { type: String,  default: '', required: false}
    },
    data(){
        return{
            linkBase:'/',
            imgPath:this.src,
            item:{},
        }
    },
    watch:{
      src(val){
        this.imgPath = val
      }
    },
    computed: {
        lazyPath () {
          if(process.env.NODE_ENV ==='development'){
            this.linkBase = process.env.FILE_URL
          }

          if(this.src){
            return this.linkBase+this.src
          }else{
            if(this.defSrc){
              return require(`@/assets/icon/${this.defSrc}`)
            }
          }          
          return ''
        },
    },    
    created(){
      //console.log('this.imgPath::' + this.imgPath)
        // if(process.env.NODE_ENV ==='development'){
        //     this.linkBase = process.env.FILE_URL
        // }
        // if(this.src){
        //   this.imgPath = this.linkBase+this.src
        // }else{
        //   this.imgPath = require(`@/assets/icon/${this.defSrc}`)
        // }
    },    
    methods:{
      replaceNoImg(e){
        if(this.type ==='S'){
            e.target.src = require(`@/assets/icon/img_noimage_70.svg`)
          }else{
            e.target.src = require(`@/assets/icon/img_noimage_360.svg`)
        }        
      },
    }
}
</script>