<template>
    <div class="detail_info">
        <div class="detail_info_textarea fs-17 regular">
            <div v-html="dhtml" :style="{color:textColor, 'font-size': fontSize +'px', 'white-space': 'pre-wrap',}">
            </div>              
        </div>
    </div>
</template>
<script>
export default {
    name: 'DListItemHtml',
    props: {
        dhtml: { type: String,  default: '', required: true},
        textColor: { type: String,  default: '#000', },
        fontSize: { type: Number,  default: 16, },
    },
}
</script>