<template>
  <div class="header__wrap">
    <div></div>
    <div class="header__logo">
      <img src="@/assets/icon/header_logo.svg" alt="">
    </div>
    <div class="header__icon">
      <a @click="$router.go(-1)"><img src="@/assets/icon/ico_x_24dp.svg"></a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'HeaderSub',
}
</script>

<!-- <style scoped>
.header__wrap {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 360px;
  height: 48px;
  background: #FFFFFF;
  border-bottom: 1px solid #DDDDDD;
}
.header__logo{
  position: absolute;
  top: 12px;
  left: 147px;
}
.header__icon{
  position: absolute;
  top: 12px;
  left: 324px;
  cursor: pointer;
}
</style> -->